import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/data-user",
        name: "data-user",
        component: () => import("./pages/data/user/User.vue"),
      },
      {
        path: "/data-user/:id/detail",
        name: "data-user-detail",
        component: () => import("./pages/data/user/Detail.vue"),
      },
      {
        path: "/report-account-development",
        name: "report-account-development",
        component: () => import("./pages/report/AccountDevelopment.vue"),
      },
      {
        path: "/report-sales-review",
        name: "report-sales-review",
        component: () => import("./pages/report/SalesReview.vue"),
      },
      {
        path: "/report-business-review",
        name: "report-business-review",
        component: () => import("./pages/report/BusinessReview.vue"),
      },
      {
        path: "/report-admin-work",
        name: "report-admin-work",
        component: () => import("./pages/report/AdminWork.vue"),
      },
      {
        path: "/report-join-visit",
        name: "report-join-visit",
        component: () => import("./pages/report/JoinVisit.vue"),
      },
      {
        path: "/report-field-tracking",
        name: "report-field-tracking",
        component: () => import("./pages/report/FieldTracking.vue"),
      },
      {
        path: "/report-user-active",
        name: "report-user-active",
        component: () => import("./pages/report/UserActive.vue"),
      },
      {
        path: "/report-call-plan",
        name: "report-call-plan",
        component: () => import("./pages/report/CallPlan.vue"),
      },
      {
        path: "/report-compliance",
        name: "report-compliance",
        component: () => import("./pages/report/Compliance.vue"),
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !localStorage.getItem("authToken")) {
    next({ name: "login" });
  } else if (to.name === "login" && localStorage.getItem("authToken")) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
